import React from "react";

type Skill = {
  text: string;
  children?: Skill[];
};

type Contact = {
  label: string;
  href: string;
  text: string;
};

export const renderRecursiveList = (skill: Skill | Skill[], key?: number) => {
  if (Array.isArray(skill)) {
    return (
      <ul>{skill.map((child, index) => renderRecursiveList(child, index))}</ul>
    );
  }
  return (
    <li key={key}>
      {skill.text}
      {skill.children && <>{renderRecursiveList(skill.children)}</>}
    </li>
  );
};

export const renderContact = (contacts: ReadonlyArray<Contact>) => (
  <ul className="contact">
    {contacts.map(({ label, href, text }, index) => {
      return (
        <li key={index}>
          {label}:&nbsp;
          <a
            href={href}
            target="_blank"
            rel="noreferrer"
            className="text-white"
          >
            {text}
          </a>
        </li>
      );
    })}
  </ul>
);
