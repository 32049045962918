import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import data from "./data.json";
import { renderRecursiveList } from "./utils";
import { SideBar } from "./SideBar";

const App = () => (
  <Row>
    <SideBar />
    <Col>
      <Container fluid>
        <p className="h3 text-uppercase">{data.details.name}</p>
        <p className="h5">{data.details.position}</p>
        <br />
        <p className="h5 text-uppercase">Summary</p>
        <div className="text-justify">
          {data.details.summary.map((summary, index) => {
            return <p key={index}>{summary}</p>;
          })}
        </div>
        <br />
        <p className="h5 text-uppercase">Education</p>
        <ul>
          {data.education.map((education, index) => {
            return (
              <li key={index}>
                <p>
                  <span className="lead">
                    <strong>{education.school}</strong>
                  </span>
                  <br />
                  <span className="title">{education.title}</span>
                  <br />
                  {education.specialization && (
                    <>
                      {education.specialization}
                      <br />
                    </>
                  )}
                  {education.date}
                  <br />
                  <span className="text-muted">{education.address}</span>
                </p>
              </li>
            );
          })}
        </ul>
        <p className="h5 text-uppercase">Experience</p>
        <ul>
          {data.experience.map((experience, index) => {
            return (
              <li key={index}>
                <p>
                  <span className="lead">
                    <strong>{experience.name}</strong>
                  </span>
                  {experience.dates && (
                    <>
                      <br />
                      {experience.dates}
                      <br />
                      <br />
                    </>
                  )}

                  {experience.positions.map((position, index) => {
                    return (
                      <div key={index}>
                        <span className="title">{position.position}</span>
                        <br />
                        {position.dates}
                        <br />
                        <span className="text-muted">{position.address}</span>
                        <ul>
                          {position.description.map((description, index) => (
                            <li key={index}>{description}</li>
                          ))}
                        </ul>
                        <br />
                      </div>
                    );
                  })}
                </p>
              </li>
            );
          })}
        </ul>
        <p className="h5 text-uppercase">Skills/Qualifications</p>
        {renderRecursiveList(data.skills)}
      </Container>
    </Col>
  </Row>
);

export default App;
