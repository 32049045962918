import React from "react";
import { Col, Container } from "react-bootstrap";
import data from "./data.json";
import { renderContact, renderRecursiveList } from "./utils";

export const SideBar = () => (
  <Col sm={5} md={4} lg={3} className="sidebar">
    <Container fluid>
      <h5>Contact</h5>
      {renderContact(data.contact)}
      <h5>Skill Summary</h5>
      {renderRecursiveList(data.skill_summary)}
    </Container>
  </Col>
);
